/* Header Styles */
.header {
  background: rgba(47, 45, 47, 0.49);
  height: auto; /* Adjust height to fit content */
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10;
}
.headers {
  background: rgba(47, 45, 47, 0.49);
  height: auto; /* Adjust height to fit content */
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10;
}
.logo {
  display: flex;
  align-items: center;
}

.logo-icon {
  background-color: #ffdb00;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.logo-icon span {
  font-size: 20px;
  color: #4a4a4a;
}

.logo-text {
  color: white;
  font-size: 18px;
  font-weight: bold;
}
.logo-img{
  width: 33%;
}
/* Default Navigation for Desktop */
.nav {
  display: flex;
  gap: 20px;
}

.nav-item {
  color: white;
  text-decoration: none;
}

.nav-item:hover {
  text-decoration: underline;
}

/* Hamburger menu icon for mobile */
.menu-icon {
  display: none;
  cursor: pointer;
}

.menu-icon div {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 5px 0;
  transition: 0.4s;
}

/* Mobile Navigation */
@media screen and (max-width: 768px) {
  /* On mobile screens */
  .header {
    flex-wrap: wrap;
    padding: 10px;
  }
.logo-img{
  width: 50%;
}
.logo{
  justify-content: space-between;
}
  .nav {
    position: fixed;
    top: 0;
    left: -250px; /* Initially hidden off-screen */
    height: 100%;
    width: 250px; /* Width of the navigation */
    background-color: rgba(47, 45, 47, 0.99); /* Adjust background color */
    transition: left 0.3s ease; /* Animation for sliding effect */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    flex-direction: column;
    align-items: center;
    display: none; /* Hidden by default */
    padding-top: 50px;
  }

  .nav.active {
    left: 0; /* Slide in when active */
    display: flex; /* Show nav when active */
  }

  .nav-item {
    padding: 15px 0;
    width: 100%; /* Make items take full width */
    text-align: center; /* Center text */
  }

  .menu-icon {
    display: block; /* Show menu icon on mobile */
  }
}

/* For even smaller screens */
@media screen and (max-width: 480px) {
  .logo-text {
    font-size: 16px;
  }

  .logo-icon {
    width: 25px;
    height: 25px;
  }

  .logo-icon span {
    font-size: 16px;
  }
}

/* Desktop and Larger Screens */
@media screen and (min-width: 769px) {
  /* Navigation behaves normally on larger screens */
  .nav {
    position: static; /* Default position */
    display: flex; /* Visible flex layout */
    flex-direction: row; /* Horizontal layout */
    width: auto; /* Auto width */
    height: auto; /* Auto height */
    background: transparent; /* No background needed */
    box-shadow: none; /* No shadow */
  }

  .nav-item {
    padding: 0;
  }

  .menu-icon {
    display: none; /* Hide menu icon on larger screens */
  }
}
