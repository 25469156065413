.home-container {
  display: flex;
  width: 100%;
  height: 100vh;
}

.content-container {
  width: 55%;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
}

.explore-btn-main {
  background: #9b9b9b;
  color: white;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: rgba(47, 45, 47, 0.49);
  --font-coolvetica: Coolvetica;
}

.logo {
  display: flex;
  align-items: center;
  color: white;
  gap: 12px;
}

.nav-links {
  display: flex;
  list-style-type: none;
  gap: 40px;
  padding: 0;
}

.head {
  font-size: 111px;
  font-weight: 500;
  line-height: 153.6px;
  text-align: left;
  width: 100%;
  margin: 0;
  font-family: coov;
}

.nav-links li {
  margin-left: 20px;
  color: white;
}

.menu-icon {
  display: none;
  cursor: pointer;
}

.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 142px;
  align-items: baseline;
  gap: 20px;
  width: 64%;
}

h1 {
  font-size: 48px;
  margin-bottom: 20px;
}
.main-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.para {
  width: 80%;
  text-align: left;
  font-size: 24px;
  line-height: 28.5px;
  color: rgba(150, 150, 150, 1);
  /* font-family: bas; */
}

.explore-btn {
  align-self: flex-start;
  padding: 10px 20px;
  background-color: rgb(150 150 150);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 8px;
}

.slider-dots {
  display: flex;
  margin-top: 20px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin-right: 10px;
}

.dot.active {
  background-color: #333;
}

.image-container {
  width: 50%;
  position: relative;
}

.main-image {
  width: 100%;
  height: 100%;
}

.image-thumbnails {
  /* position: absolute;
  bottom: 60px;
  left: 20px; */
}

.image-thumbnails img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 10px;
}

.watch-video-btn {
  position: absolute;
  bottom: 20px;
  right: 250px;
  padding: 26px 49px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
}
.e-magazine-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 26px 49px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;

}

.home-main-div {
  padding: 80px 54px 80px 54px;
  background-color: rgba(217, 217, 217, 1);
}

.latest-post-div {
  padding: 54px 54px;
}

.most-post-div {
  padding: 70px 54px 154px 54px;
  background: rgba(217, 217, 217, 1);
}

.head-latest {
  font-family: Coolvetica;
  font-size: 90px;
  font-weight: 500;
  line-height: 153.6px;
  margin-top: -75px;
  text-align: center
}

.head-latests {
  font-family: Coolvetica;
  font-size: 90px;
  font-weight: 500;
  line-height: 153.6px;
  margin-bottom: -68px;
  text-align: center
}

/* Footer specific styles */
footer {
  background-color: rgba(47, 45, 47, 1);
  color: #ffffff;
  padding: 2rem 0;
}

footer a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

footer a:hover {
  color: #d1d5db;
}

.footer-logo {
  height: 2rem;
  margin-bottom: 1rem;
}

.footer-nav {
  margin-bottom: 1rem;
}

.footer-nav ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.copyright {
  font-size: 0.875rem;
  color: #9ca3af;
}

/* New styles for video modal */
.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.video-content {
  position: relative;
  width: 80%;
  max-width: 800px;
}

.close-video {
  position: absolute;
  top: -40px;
  right: -40px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.video-content iframe {
  width: 100%;
  height: 56.25vw;
  max-height: 450px;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .home-container {
    flex-direction: column;
    height: auto;
  }

  .content-container,
  .image-container {
    width: 100%;
  }

  .main-content {
    padding: 22px;
    width: 86%;
  }

  .head {
    font-size: 64px;
    line-height: 76.8px;
    --font-coolvetica: Coolvetica;
  }

  .para {
    font-size: 18px;
    line-height: 21.6px;
  }

  .image-thumbnails {
    position: static;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .watch-video-btn {
  
   font-size: 14px;
   padding: 20px;
   right: 235px;
 
  }
  .e-magazine-btn {
    font-size: 14px;
    padding: 20px;
  
  }
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .navbar {
    flex-direction: row-reverse;
    padding: 20px 36px;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    gap: 20px;
    width: 100%;
  }

  .nav-links.active {
    display: flex;
  }

  .navbar {
    flex-wrap: wrap;
  }

  .head-latest,
  .head-latests {
    font-size: 48px;
    line-height: 57.6px;
    margin-top: -31px;
    margin-bottom: -20px;
  }

  .home-main-div,
  .latest-post-div,
  .most-post-div {
    padding: 30px 20px;
  }

  /* Responsive styles for video modal */
  .video-content {
    width: 90%;
  }

  .close-video {
    top: -30px;
    right: 0;
  }
}

@media (max-width: 480px) {
  .head {
    font-size: 48px;
    line-height: 57.6px;
  }

  .para {
    font-size: 16px;
    line-height: 19.2px;
  }

  .image-thumbnails img {
    width: 50px;
    height: 50px;
  }

  /* Responsive styles for video modal */
  .video-content iframe {
    height: 56.25vw;
    max-height: none;
  }
}

/* Footer responsive styles */
@media (max-width: 640px) {
  .footer-nav ul {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  .social-icons {
    flex-wrap: wrap;
    justify-content: center;
  }
}