.frame-parent {
  width: 1177px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-8xl);
  max-width: 100%;
}

.desktop-3-inner {
  max-width: 1419px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  box-sizing: border-box;

  margin: auto;
}

.spatial-div {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  padding-left: 200px;
  height: 510px;
}

.at-the-core,
.frame-child {
  position: relative;
  max-width: 100%;
}

.frame-child {
  height: 262px;
  width: 959px;
  background-color: var(--color-whitesmoke);
  display: none;
  z-index: 0;
}

.at-the-core {
  width: 856px;
  white-space: pre-wrap;
  display: inline-block;
  flex-shrink: 0;
  z-index: 1;
}

.img-0492-4-icon {
  height: 515px;
  width: 564px;
  position: absolute;
  margin: 0 !important;
  right: 46px;
  bottom: -462px;
  object-fit: cover;
  z-index: 1;
}

.desktop-3-child,
.rectangle-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.rectangle-parent {
  width: 100%;
  background-color: var(--color-whitesmoke);
  justify-content: flex-end;
  padding: 107px var(--padding-27xl) var(--padding-54xl);
  position: relative;
  top: -45px;
}

.img-1 {
  z-index: 2;
  position: relative;
}

.spatial {
  max-width: 1419px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  margin: auto;
}

.desktop-3-child {
  max-width: 1429px;
  justify-content: center;

  text-align: justify;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-poppins);
  margin: auto;
}

.desktop-3-4 {
  max-width: 1414px;
  margin: auto;
}

.frame-item {
  height: 336px;
  width: 397px;
  position: relative;
  background-color: var(--color-whitesmoke);
  display: none;
  max-width: 100%;
}

.orientation-of-the {
  width: 272px;
  position: relative;
  white-space: pre-wrap;
  display: inline-block;
  flex-shrink: 0;
  z-index: 1;
}

.frame-div,
.rectangle-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.rectangle-group {
  align-self: stretch;
  width: 397px;
  background-color: var(--color-whitesmoke);
  justify-content: flex-start;
  padding: 79px var(--padding-38xl);
}

.frame-div {
  width: 867px;
  height: 707px;
  justify-content: center;
  padding: 0 var(--padding-xl) 371px;
}

.frame-inner,
.the-gazebo-is {
  position: relative;
  max-width: 100%;
}

.frame-inner {
  height: 163px;
  width: 681px;
  background-color: var(--color-whitesmoke);
  display: none;
}

.the-gazebo-is {
  flex: 1;
  white-space: pre-wrap;
  display: inline-block;
  z-index: 1;
}

.rectangle-container {
  align-self: stretch;
  background-color: var(--color-whitesmoke);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 59px var(--padding-38xl) var(--padding-9xl);
  box-sizing: border-box;
  max-width: 100%;
  height: 200px;
}

.rectangle-container1 {
  background-color: var(--color-whitesmoke);
  padding: 57px;
  top: -57px;
  left: -67px;
  position: relative;
  width: 297px;

}

.rectangle-container-2 {
  background-color: var(--color-whitesmoke);
  padding: 48px;
  top: -36px;
  left: 139px;
  position: relative;
  width: 664;
  width: 662px;
}

.rectangle-container-4 {
  background-color: var(--color-whitesmoke);
  padding: 48px;
  height: 406px;
  position: relative;
  top: -51px;
  width: 650px;

}

.image-div-1 {
  position: relative;
  top: -51px;

}

.div-3 {
  display: flex;
  margin-top: -58px;
  position: relative;
  left: 45px;
}

.rectangle-containers {
  align-self: stretch;
  background-color: var(--color-whitesmoke);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 59px var(--padding-38xl) var(--padding-9xl);
  box-sizing: border-box;
  max-width: 100%;
  height: 287px;
  position: relative;
  top: 340px;
  left: 35px;
}

.images-22 {
  position: relative;
  top: -15px;
  left: 199px;
  z-index: 1;
}

.div {
  display: flex;
  position: relative;
  top: -67px;
}

.spatial-div2 {
  display: flex;
  gap: 20px;
  padding-left: 202px;

}

.functionality-materiality {
  margin: 0;
  height: 93px;
  width: 272px;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  z-index: 1;
}

.functionality-materiality-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-38xl);
}

.rectangle-div {
  height: 408px;
  width: 395px;
  position: relative;
  background-color: var(--color-whitesmoke);
  display: none;
  max-width: 100%;
  z-index: 0;
}

.img-0492-11-icon {
  height: 472px;
  width: 564px;
  position: absolute;
  margin: 0 !important;
  top: -93px;
  right: -518px;
  object-fit: cover;
  z-index: 1;
}

.div-4 {
  display: flex;
  gap: 40px;
  position: relative;
  left: 145px;
  margin-top: -20px;
  flex-direction: row;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-end;
}

.image9 {
  width: 534px;
  height: 507px;
}

.image10 {
  width: 270px;
  height: 352px;
}

.image11 {
  position: relative;
  left: 139px;
}

.div-6 {
  width: 846px;
  position: relative;
  left: 143px;

}

.rectangle-parent1 {
  align-self: stretch;
  flex: 1;
  background-color: var(--color-whitesmoke);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-4xl) var(--padding-38xl);
  box-sizing: border-box;
  position: relative;
  max-width: 100%;
  text-align: justify;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-montserrat);
}

.frame-container {
  width: 395px;
  height: 501px;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-17xl);
  color: var(--color-darkslategray-100);
  font-weight: 500px;
  position: relative;
  top: -77px;
  left: 139px;
}

.desktop-3,
.frame-container,
.frame-group,
.frame-section {
  max-width: 100%;
  display: flex;
  align-items: flex-start;
}

.frame-group {
  width: 950px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-smi);
  margin: auto;
  max-width: 1419px;
}

.desktop-3,
.frame-section {
  box-sizing: border-box;
  text-align: justify;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-montserrat);
}

.frame-section {
  max-width: 1160px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  margin: auto;
}

.desktop-3 {
  background-color: var(--color-white);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-8xl);
  line-height: normal;
  letter-spacing: normal;
  margin-top: 35px;

}

.div-7 {
  width: 846px;
  position: relative;
  left: 401px;

}

.image12 {
  width: 100%;
  margin-bottom: -7px;
}

.frame-child1 {
  height: 98px;
  width: 1440px;
  position: relative;
  background-color: var(--color-darkslategray-200);
  display: none;
  max-width: 100%;
}

.home {
  text-decoration: none;
  position: relative;
  color: inherit;
  display: inline-block;
  min-width: 40px;
  z-index: 1;
}

.home-wrapper {
  width: 65px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) 0 0;
  box-sizing: border-box;
}

.corporate1 {
  margin: 0;
}

.corporate,
.products {
  text-decoration: none;
  position: relative;
  color: inherit;
  display: inline-block;
  z-index: 1;
}

.corporate {
  width: 90px;
  flex-shrink: 0;
}

.products {
  min-width: 61px;
}

.products-wrapper {
  width: 87px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) 0 0;
  box-sizing: border-box;
}

.get-inspired {
  text-decoration: none;
  width: 93px;
  position: relative;
  color: inherit;
  display: inline-block;
  flex-shrink: 0;
  white-space: nowrap;
  z-index: 1;
}

.frame-header {
  align-self: stretch;
  background-color: var(--color-darkslategray-200);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: var(--padding-21xl) var(--padding-24xl) var(--padding-20xl);
  box-sizing: border-box;
  gap: var(--gap-smi);
  top: 0;
  z-index: 99;
  position: sticky;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-white);
  font-family: var(--font-coolvetica);
}

@media screen and (max-width: 450px) {
  .frame-header {
    padding-left: var(--padding-2xl);
    padding-right: var(--padding-2xl);
    box-sizing: border-box;
  }
}

.mask-group-icon {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.embracing-nature-and {
  width: 564px;
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
  z-index: 1;
}

.mask-group-parent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-7xl) var(--padding-16xl);
  box-sizing: border-box;
  position: relative;
  min-height: 524px;
  max-width: 100%;
}

.frame-child2 {
  width: 422px;
  height: 524px;
  position: relative;
  background-color: var(--color-whitesmoke);
  display: none;
  max-width: 100%;
}

.celendine,
.this-sunlit-breathing {
  position: relative;
  display: inline-block;
}

.celendine {
  margin: 0;
  align-self: stretch;
  height: 82px;
  font-size: 53px;
  font-weight: 400;
  font-family: inherit;
  flex-shrink: 0;
  z-index: 2;
}

.this-sunlit-breathing {
  width: 272px;
  font-size: var(--font-size-base);
  font-family: var(--font-montserrat);
  color: var(--color-black);
  white-space: pre-wrap;
  text-align: justify;
  z-index: 1;
}

.frame-parent1,
.rectangle-parent2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.rectangle-parent2 {
  width: 422px;
  background-color: var(--color-whitesmoke);
  flex-direction: column;
  padding: 20px var(--padding-53xl) var(--padding-24xl);
  box-sizing: border-box;
  min-width: 422px;
  text-align: left;
  font-size: var(--font-size-45xl);
  color: var(--color-darkslategray-100);
  font-family: var(--font-coolvetica);
}

.frame-parent1 {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-xl);
  text-align: justify;
  font-size: var(--font-size-base);
  color: var(--color-white);
  font-family: var(--font-montserrat);
}

.frame-child3 {
  width: 422px;
  height: 524px;
  position: relative;
  background-color: var(--color-whitesmoke);
  display: none;
  max-width: 100%;
  z-index: 0;
}

.site-context {
  margin: 0;
  align-self: stretch;
  height: 82px;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  z-index: 2;
}

.the-15-cent-site {
  margin-top: -36px;
  flex: 1;
  position: relative;
  white-space: pre-wrap;
  z-index: 1;
}

.the-15-cent-site-in-muringoor-wrapper {
  width: 272px;
  height: 230px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  text-align: justify;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-montserrat);
}

.img-0484-1-icon,
.img-0484-2-icon {
  width: 564px;
  position: absolute;
  margin: 0 !important;
  object-fit: cover;
}

.img-0484-1-icon {
  height: 336px;
  right: -179px;
  bottom: -213px;
  z-index: 1;
}

.img-0484-2-icon {
  height: 318px;
  top: 60px;
  right: -491px;
  z-index: 3;
}

.frame-wrapper1,
.rectangle-parent3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.rectangle-parent3 {
  align-self: stretch;
  background-color: var(--color-whitesmoke);
  padding: var(--padding-37xl) var(--padding-9xs) var(--padding-137xl) var(--padding-38xl);
  position: relative;
}

.frame-wrapper1 {
  width: 422px;
  padding: 0 0 var(--padding-128xl);
  min-width: 422px;
  min-height: 671px;
}

.frame-child4 {
  height: 314px;
  width: 396px;
  position: relative;
  background-color: var(--color-whitesmoke);
  display: none;
  max-width: 100%;
}

.principal-architect {
  margin: 0;
  white-space: pre-wrap;
}

.project-associate-aramal {
  margin: 0;
}

.principal-architect-arshyam-container {
  position: relative;
  z-index: 1;
}

.frame-parent3,
.rectangle-parent4 {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}

.rectangle-parent4 {
  width: 396px;
  background-color: var(--color-whitesmoke);
  align-items: flex-start;
  justify-content: flex-end;
  padding: var(--padding-25xl) var(--padding-17xl) var(--padding-27xl);
  box-sizing: border-box;
  min-width: 396px;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-poppins);
}

.frame-parent3 {
  align-self: stretch;
  align-items: flex-end;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.frame-child5 {
  width: 667px;
  height: 262px;
  position: relative;
  background-color: var(--color-whitesmoke);
  display: none;
  max-width: 100%;
}

.planning {
  margin: 0;
  height: 82px;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  min-width: 129px;
  z-index: 2;
}

.dubai-family {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}

.the-clients-a {
  margin-top: -30px;
  flex: 1;
  position: relative;
  white-space: pre-wrap;
  display: inline-block;
  max-width: 100%;
  z-index: 1;
}

.dubai-family1,
.frame-parent2,
.rectangle-parent5 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.a {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.dubai-family1 {
  align-self: stretch;
  height: 95px;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  box-sizing: border-box;
  text-align: justify;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-poppins);
}

.frame-parent2,
.rectangle-parent5 {
  flex-direction: column;
}

.rectangle-parent5 {
  width: 667px;
  background-color: var(--color-whitesmoke);
  align-items: flex-start;
  padding: var(--padding-6xs) var(--padding-27xl) var(--padding-59xl) var(--padding-38xl);
  box-sizing: border-box;
  height: 256px;
}

.frame-parent2 {
  width: 959px;
  align-items: flex-end;
  gap: var(--gap-63xl);
}

.frame-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) 0 var(--padding-11xl);
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-17xl);
  color: var(--color-darkslategray-100);
  font-family: var(--font-coolvetica);
}

.architects-duality-child {
  width: 959px;
  height: 262px;
  position: relative;
  background-color: var(--color-whitesmoke);
  display: none;
  max-width: 100%;
  z-index: 0;
}

.the-architects-balance {
  align-self: stretch;
  position: relative;
  white-space: pre-wrap;
  z-index: 1;
}

.img-0492-1-icon,
.img-0492-2-icon {
  position: absolute;
  margin: 0 !important;
  object-fit: cover;
  z-index: 1;
}

.img-0492-1-icon {
  width: 564px;
  height: 401px;
  top: -352px;
  right: 46px;
}

.img-0492-2-icon {
  width: 272px;
  height: 523px;
  top: -474px;
  left: 57px;
}

.architects-duality {
  align-self: stretch;
  background-color: var(--color-whitesmoke);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-53xl) var(--padding-27xl) var(--padding-6xs) var(--padding-38xl);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-5xs);
  max-width: 100%;
}

.spatial-layout {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  font-weight: 600;
  font-family: inherit;
  /* padding: 0 var(--padding-38xl); */
  box-sizing: border-box;
  text-align: left;
  margin: 20px 0px;
  font-size: var(--font-size-17xl);
  color: var(--color-darkslategray-100);
  font-family: var(--font-coolvetica);
}

.img-0492-4-ico {
  position: relative;
  top: 30px;
}

.image-12 {
  width: 537px;
  height: 517px;
  z-index: 1;
}

.architects-duality-parent,
.spatial-layout-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.spatial-layout-wrapper {
  width: 386px;
  flex-direction: row;
  padding: 0 var(--padding-38xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-17xl);
  color: var(--color-darkslategray-100);
  font-family: var(--font-coolvetica);
}

.architects-duality-parent {
  width: 959px;
  flex-direction: column;
  gap: var(--gap-base-8);
}

.img-0492-3-icon {
  height: 486px;
  width: 856px;
  position: absolute;
  margin: 0 !important;
  right: 281px;
  bottom: -93px;
  object-fit: cover;
  z-index: 1;
}

.frame-parent4 {
  width: 1429px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-392xl-1);
  box-sizing: border-box;
  position: relative;
  max-width: 100%;
  text-align: justify;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-poppins);
}

.img-0492-6-icon {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.img-0492-6-wrapper {
  height: 567px;
  width: 272px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-62xl) 0 0;
  box-sizing: border-box;
  min-width: 272px;
}

.frame-child6 {
  height: 336px;
  width: 397px;
  position: relative;
  background-color: var(--color-whitesmoke);
  display: none;
  max-width: 100%;
  z-index: 0;
}

.the-private-bedrooms {
  width: 272px;
  position: relative;
  white-space: pre-wrap;
  display: inline-block;
  flex-shrink: 0;
  z-index: 1;
}

.img-0492-5-icon,
.img-0492-7-icon,
.img-0492-8-icon {
  position: absolute;
  margin: 0 !important;
  object-fit: cover;
  z-index: 1;
}

.img-0492-5-icon {
  height: 486px;
  width: 856px;
  top: -428px;
  left: -509px;
}

.img-0492-7-icon,
.img-0492-8-icon {
  width: 272px;
  bottom: -231px;
}

.img-0492-7-icon {
  height: 486px;
  left: -217px;
}

.img-0492-8-icon {
  height: 280px;
  right: 50px;
}

.frame-parent6,
.rectangle-parent6 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}

.rectangle-parent6 {
  width: 397px;
  background-color: var(--color-whitesmoke);
  justify-content: flex-end;
  padding: var(--padding-73xl) var(--padding-31xl) var(--padding-54xl);
  box-sizing: border-box;
  position: relative;
  min-width: 397px;
}

.frame-parent6 {
  width: 906px;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.img-0492-10-icon,
.img-0492-9-icon {
  position: absolute;
  margin: 0 !important;
  object-fit: cover;
  z-index: 1;
}

.img-0492-9-icon {
  height: 506px;
  width: 272px;
  right: 292px;
  bottom: -162px;
}

.img-0492-10-icon {
  height: 396px;
  width: 564px;
  bottom: -52px;
  left: 292px;
}

.frame-parent5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-348xl) var(--padding-51xl);
  box-sizing: border-box;
  position: relative;
  max-width: 100%;
  text-align: justify;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-montserrat);
}

.mobile-div {
  display: none;
}

.image13 {
  width: 100%;
  margin-top: -68px;
}

@media screen and (max-width: 1050px) {
  .frame-parent5 {
    padding-left: var(--padding-16xl);
    padding-bottom: var(--padding-220xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 750px) {
  .mobile-div {
    display: block;
  }

  .desktop-3 {
    display: none;
  }

  .div-mobile-1 {
    background-image: url("../../Assets/Clentaine/IMG_0471\ 2.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 641px;
    width: 100%;
    position: relative;
    top: -100px;
  }

  .mobile-head {
    font-family: coov;
    color: white;
    font-size: 48px;
  }
  .mobile-main{
    position: relative;
    top: 67%;
    padding: 25px;
  }
  .mobile-para{
    color: white;
  }

  .header {
    background: rgb(47 45 47 / 0%);
    position: relative;
  }
}
.mobile-btn{
  top: -45px;
  position: relative;
}
.e-magazine-btn {
  font-size: 14px;
  padding: 13px;
  margin-right: 26px;

}
.watch-video-btn{
  font-size: 14px;
  padding: 13px;
  right: 208px;

}
.mobile-div2{
  height: 85px;
  background-color: #E9E9E9;
  margin-top: -99px;
  font-size: 32px;
  font-family: coov;
  align-content: flex-end;
  justify-content: space-around;
  align-items: flex-end;
  flex-direction: row-reverse;
  display: flex;
}
.mobile-main-div{
  margin: auto;
  width: 346px  ;
}
.mobile-div23{
  height: 85px;
  /* background-color: #E9E9E9; */
  font-size: 32px;
  font-family: coov;
  align-content: flex-end;
  justify-content: space-around;
  align-items: flex-end;
  flex-direction: row-reverse;
  display: flex;
}
.img-0492-6-icon {
  align-self: stretch;
  width: auto;
}

.img-0492-6-wrapper {
  flex: 1;
  padding-top: var(--padding-34xl);
  box-sizing: border-box;
}

.rectangle-parent6 {
  flex: 1;
  min-width: 100%;
}

.frame-parent6 {
  flex-wrap: wrap;
  justify-content: center;
}

.frame-parent5 {
  padding-bottom: var(--padding-136xl);
  box-sizing: border-box;
}

@media screen and (max-width: 450px) {
  .rectangle-parent6 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}