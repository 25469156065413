.magazine-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    flex-direction: column-reverse;
  }
  
  .magazine {
    width: 90vw;
    height: 90vh;
    max-width: 1200px;
    max-height: 800px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
  .share-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .share-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: 40px;
    width: 40px;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .share-button svg {
  }
  
/* Base styles for share buttons */
.share-button {
  color: white;
  transition: all 0.3s ease;
  filter: grayscale(100%);
}

/* WhatsApp */
.whatsapp { background-color: #25D366; }

/* Facebook */
.facebook { background-color: #1877F2; }

/* Instagram */
.instagram { background-color: #E4405F; }

/* General */
.general { background-color: #FCE658; }

/* Hover effect */
.share-button:hover {
  opacity: 0.9;
  filter: grayscale(0%);
}
  
  /* Responsive design for mobile */
  @media (max-width: 768px) {
    .share-buttons {
      align-items: center;
      gap: 20px;
  }
    
  
    .share-button {
      margin: 5px 0;
    }
  }
  .page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  
  .page-image {
    max-width: 100%;
    max-height: calc(100% - 40px);
    object-fit: contain;
  }
  
  .page-title {
    margin-top: 10px;
    font-size: 16px;
    text-align: center;
  }
  .download-button {
    padding: 10px 20px;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    align-items: center;
    display: flex;
    gap: 6px;
    font-family: coov;

  }
  
  .download-button:hover {
   color: #FCE658;
  }
  .skeleton-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .skeleton-page {
    width: 300px;
    height: 450px;
    background-color: #e0e0e0;
    margin: 0 10px;
    position: relative;
    overflow: hidden;
  }
  
  .skeleton-page::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    animation: loading 1.5s infinite;
  }
  
  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  @media (min-width: 768px) {
    .skeleton-page {
      width: 400px;
      height: 600px;
    }
  }
  @media (max-width: 768px) {
    .magazine {
      width: 95vw;
      height: 95vh;
    }
  
    .page-title {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .page-title {
      font-size: 12px;
    }
  }