.property-gallery {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 50px;
    aspect-ratio: 738 / 348;
    
}

.property-card {
    position: relative;
    overflow: hidden;
}

.property-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.property-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    padding: 10px;
    background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
}

.property-info p {
    margin: 0 0 30px;
    font-size: 12px;
    line-height: 1.4;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: left;
    width: 310px;
}

.explore-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: #d9d9d980;
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 5px;
}
.property-title{
    margin: 8px 0px;
}
.card-title {
    position: absolute;
    background: rgba(217, 217, 217, 0.7);
    color: black;
    top: 10px;
    left: 10px;
    padding: 8px 14px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
}

.large {
    grid-column: 1;
    grid-row: 1 / -1;
}

.small {
    grid-column: span 1;
    grid-row: span 1;
}

.wide {
    grid-column: 2 / -1;
    grid-row: 2;
}

/* Responsive styles */
@media (max-width: 1024px) {
    .property-gallery {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        aspect-ratio: auto;
    }

    .large, .wide {
        grid-column: span 2;
    }

    .small {
        grid-column: span 1;
    }
}

@media (max-width: 768px) {
    .property-gallery {
        grid-template-columns: 1fr;
        gap: 30px;
    }

    .large, .small, .wide {
        grid-column: 1;
        grid-row: auto;
        aspect-ratio: 7 / 9;
    }

    .property-info p {
        font-size: 14px;
    }

    .explore-btn, .card-title {
        font-size: 14px;
        padding: 8px 15px;
    }
}

@media (max-width: 480px) {
    .property-gallery {
        gap: 15px;
    }

    .property-info p {
        font-size: 12px;
        -webkit-line-clamp: 2;
    }

    .explore-btn, .card-title {
        font-size: 12px;
        padding: 5px 10px;
    }
}