.celendine-container {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
    gap: 30px;
  }
  .magazine-div{
    max-width: 1400px;
  }
  .image-container {
    flex: 2;
    height: 400px;
  }
  
  .image-container img {
    width: 100%;
    height: 100%;
  }
  
  /* .content-container {
    flex: 1;
    padding: 7rem;
    background-color: rgba(233, 233, 233, 1);
  } */
  
  .content-container h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .content-container p {
    font-size: 0.875rem;
    line-height: 1.5;
  }
  .site-context-wrapper {
    display: flex;
    padding-top: 2rem;
    max-width: 900px;
    margin: auto;
    flex-direction: row;

  }

  .site-context-content {
    margin-bottom: 1rem;
    width: 422px;
    background: rgba(233, 233, 233, 1);
    padding: 2rem;

  }

  .site-context-title {
    font-size: 1.875rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #1f2937;
  }

  .site-context-description {
    color: #4b5563;
    line-height: 1.6;
    width: 300px;
  }

  .site-context-image-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;

  }

  .site-context-image {
    position: absolute;
    top: 50px;
    left: -95px;
    width: 111%;

z-index: 2;
  }


  @media (min-width: 768px) {
    .celendine-container {
      flex-direction: row;
    }
  
    .image-container {
      height: auto;
    }
  }