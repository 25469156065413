

@font-face {
  font-family: coov;
  src: url("../public/coolvetica\ rg\ copy.otf");
}
@font-face {
  font-family: bas;
  src: url("../public/BaketFashion-DemoVersion-Regular.otf");
}
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
font-family: montserrat;

  /* font sizes */
  --font-size-base: 16px;
  --font-size-17xl: 36px;
  --font-size-3xl: 22px;
  --font-size-10xl: 29px;
  --font-size-45xl: 64px;
  --font-size-19xl: 38px;
  --font-size-32xl: 51px;

  /* Colors */
  --color-white: #fff;
  --color-whitesmoke: #e9e9e9;
  --color-black: #000;
  --color-darkslategray-100: #2f2d2f;
  --color-darkslategray-200: rgba(47, 45, 47, 0.49);

  /* Gaps */
  --gap-8xl: 0px;
  --gap-smi: 13px;
  --gap-xl: 20px;
  --gap-base-8: 15.8px;
  --gap-5xs: 8px;
  --gap-63xl: 82px;
  --gap-22xl: 41px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-4xl: 23px;
  --padding-38xl: 57px;
  --padding-9xl: 28px;
  --padding-348xl: 367px;
  --padding-51xl: 70px;
  --padding-136xl: 155px;
  --padding-16xl: 35px;
  --padding-220xl: 239px;
  --padding-73xl: 92px;
  --padding-31xl: 50px;
  --padding-54xl: 73px;
  --padding-62xl: 81px;
  --padding-34xl: 53px;
  --padding-27xl: 46px;
  --padding-392xl-1: 411.1px;
  --padding-248xl: 267px;
  --padding-53xl: 72px;
  --padding-6xs: 7px;
  --padding-11xl: 30px;
  --padding-59xl: 78px;
  --padding-25xl: 44px;
  --padding-17xl: 36px;
  --padding-128xl: 147px;
  --padding-77xl: 96px;
  --padding-37xl: 56px;
  --padding-9xs: 4px;
  --padding-137xl: 156px;
  --padding-82xl: 101px;
  --padding-24xl: 43px;
  --padding-7xl: 26px;
  --padding-21xl: 40px;
  --padding-20xl: 39px;
  --padding-2xl: 21px;
}
